import useJwt from '@/auth/jwt/useJwt'
import Cookies from "js-cookie";

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */

export const isUserLoggedIn = () => {
  return !!Cookies.get(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => Cookies.get(useJwt.jwtConfig.storageAdminNameKey);

export const getUserToken = () => Cookies.get(useJwt.jwtConfig.storageTokenKeyName);
